import { InfoCircleOutlined } from '@ant-design/icons';
import { Popover, Spin } from 'antd';
import { ImgFormatter } from 'egenie-utils';
import { observer } from 'mobx-react';
import React from 'react';
import noPic from '../../assets/images/noPic.png';
import { getPlatformTypeIcon } from '../../utils';
import { CopyIcon } from '../components';
import type { EachColumnProps, EachHeaderProps } from '../components/orderGrid/store';
import styles from './index.less';
import type { Store } from './store';

// 订单表格列信息
export const GRID_COLUMNS = (context: Store): EachColumnProps[] => {
  return [
    {
      width: 10,
      name: '店铺商品信息',
      merge: false,
      detailFormatter: (detail, row) => {
        const { pic, skuDesc, itemName, skuNo } = detail;
        return (
          <div className={styles.productInfo}>
            {
              pic ? (
                <ImgFormatter
                  height={56}
                  value={pic}
                  width={56}
                />
              ) : (
                <img
                  className={styles.imgStyle}
                  src={noPic}
                />
              )
            }
            <div className={styles.productText}>
              <div>
                {itemName}
              </div>
              <div>
                款式编码：
                {skuNo}
              </div>
              <div>
                店铺规格：
                {skuDesc}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      width: 5,
      name: '单价(元)/数量',
      merge: false,
      detailFormatter: (detail, row) => {
        return (
          <div className={styles.columnFlex}>
            <div>
              ¥
              {detail.price}
            </div>
            <div>
              x
              {detail.num}
            </div>
          </div>
        );
      },
    },
    {
      width: 4,
      name: '退货状态',
      merge: false,
      detailFormatter: (detail, row) => {
        return (
          <div className={styles.columnFlex}>
            <div className={styles.returnStatusWrap}>
              {detail.refundStatus}
            </div>
          </div>

        );
      },
    },
    {
      width: 8,

      // @ts-ignore
      name: <MatchTitle/>,
      merge: false,
      detailFormatter: (detail, row) => {
        return (
          <div className={styles.columnFlex}>
            {!detail?.goodsSkuId && (
              <div
                className={styles.matchBtn}
                onClick={() => {
                  context.matchGoods(detail.platformProductId);
                }}
              >
                <div>
                  去关联
                </div>
                <i
                  className="icon-sq"
                  style={{ color: '#1978ff' }}
                />
              </div>
            )}
            {detail?.goodsSkuId && (
              <GoodsDetail
                info={detail.goodsSkuTo}
                loading={context.loading}
              />
            )}
          </div>
        );
      },
    },
    {
      width: 150,
      name: '订单状态',
      merge: true,
      detailFormatter: (row) => {
        return (
          <div className={styles.columnFlex}>
            {row.distributionStateDesc}
          </div>
        );
      },
    },
    {
      width: 150,
      name: '代发状态',
      merge: true,
      detailFormatter: (row) => {
        return (
          <div className={styles.columnFlex}>
            <div>
              {row.proxySendStatusDesc}
            </div>
            {row.proxySendStatus === 2 && (
              <span
                className={styles.btnLink}
                onClick={() => {
                  window.top.egenie.openTab(`/egenie-ts-vogue/myOrder/index?erpSaleOrderNo=${row.saleOrderNo}&orderType=2`, '753', '我的订单');
                }}
              >
                查看代发订单
                &nbsp;
                &gt;
              </span>
            )}
          </div>
        );
      },
    },
    {
      width: 150,
      name: '操作',
      merge: true,
      detailFormatter: (row) => {
        const { proxySendStatus, isInvalidated } = row;
        return (
          <div className={styles.columnFlex}>
            {proxySendStatus === 1 && (
              <span
                className={styles.btnLink}
                onClick={() => {
                  context.batchDropShip(row.saleOrderId, row.tenantId);
                }}
              >
                一件代发
              </span>
            )}
            {isInvalidated !== 1 && (
              <span
                className={styles.btnLink}
                onClick={() => {
                  context.batchInvalid(row.saleOrderId);
                }}
              >
                作废
              </span>
            )}
          </div>
        );
      },
    },
  ];
};

// 每一项的标题头
export const ITEM_HEADER_COLUMNS = (context: Store): EachHeaderProps[] => {
  return [
    {
      width: '300px',
      key: 'afterSaleNo',
      rowFormatter: (row) => {
        return (
          <>
            <div
              className={styles.platformOrderCode}
              title={row.platformOrderCode}
            >
              店铺单号：
              {row.platformOrderCode}
            </div>
            <CopyIcon text={row.platformOrderCode}/>
          </>
        );
      },
    },
    {
      width: '208px',
      key: 'created',
      rowFormatter: (row) => {
        return (
          <div>
            下单时间：
            {row.created}
          </div>
        );
      },
    },
    {
      width: '20%',
      key: 'shopName',
      rowFormatter: (row) => {
        const { shopName, platformType } = row;
        return (
          <div className={styles.nameImgWrap}>
            <img
              alt=""
              className={styles.platformIcon}
              src={getPlatformTypeIcon(platformType)}
            />
            <span className={styles.commonTxt}>
              {shopName}
            </span>
          </div>
        );
      },
    },
  ];
};

export const GoodsDetail = observer((props) => {
  const { mainPicUrl, goodsName, goodsSpec, sellerOuterNo, cloudWarehouseName, skuPlatformWholePrice } = props.info || {};
  return (
    <Spin spinning={props.loading}>
      <div className={styles.goodsDetailWrap}>
        <img
          alt=""
          src={mainPicUrl || noPic}
        />
        <div className={styles.goodsInfoWrap}>
          <div
            className={`${styles.goodsName} ${styles.commonTxt}`}
            title={goodsName}
          >
            {goodsName}
          </div>
          <div className={`${styles.goodsCloud} ${styles.fs12} ${styles.fw400} ${styles.fc1978ff}`}>
            {cloudWarehouseName}
          </div>
          <div className={styles.commonTxt}>
            货号：
            {sellerOuterNo}
          </div>
          <div className={styles.commonTxt}>
            规格：
            {goodsSpec}
          </div>
          <div className={styles.commonTxt}>
            批发价：
            {skuPlatformWholePrice}
          </div>
        </div>
      </div>
    </Spin>
  );
});

const MatchTitle = observer(() => {
  return (
    <div>
      <span style={{ marginRight: 4 }}>
        关联衫海精商品
      </span>
      <Popover
        content={(
          <div
            className={`${styles.fw400} ${styles.fc2b2e3e}`}
            style={{ width: 190 }}
          >
            店铺里的商品需要和衫海精平台商品关联上才能代发，如果非衫海精的商品可忽略。
          </div>
        )}
        placement="top"
      >
        <InfoCircleOutlined style={{ fontSize: 12 }}/>
      </Popover>
    </div>
  );
});
