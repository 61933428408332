import { observable, action } from 'mobx';
import { message } from 'antd';

export default class DropShippingTipStore {
  constructor(options) {
    this.parent = options.parent;
  }

  public parent;

  public selectedRows = [];// 选中的订单

  public queryParams;// 查询条件

  @observable public visible = false;

  @observable public value = 1;

  @observable public selectedOrderDisabled = false;// 选中行选项disabled

  @action public toggleDropModal = (visible: boolean, selectedRows?, queryParams?) => {
    if (visible) {
      this.selectedRows = selectedRows;
      this.queryParams = queryParams;

      // 打开弹窗
      if (!selectedRows.length) {
        // 没有选中行
        this.value = 2;
        this.selectedOrderDisabled = true;
      } else {
        this.value = 1;
        this.selectedOrderDisabled = false;
      }
    }
    this.visible = visible;
  };

  @action public handleValueChange = (val) => {
    this.value = val;
  };

  @action public handleOk = () => {
    this.toggleDropModal(false);

    // 有选中的订单
    if (this.value === 1) {
      const isNotIssuing = this.selectedRows.every((item) => {
        return String(item.proxySendStatus) === '1' || String(item.proxySendStatus) === '3';
      });
      if (!isNotIssuing) {
        message.warning('请选择未代发或取消代发的订单！');
        return;
      }
      const ids = this.selectedRows.map((item) => item.saleOrderId);
      const tenantIds = this.selectedRows.map((item) => item.tenantId);
      console.log(`/egenie-ts-oms/wholeSalePage?ids=${ids}&tenantIds=${tenantIds}`);
      window.top.egenie.openTab(`/egenie-ts-oms/wholeSalePage?ids=${ids}&tenantIds=${tenantIds}`, 'wholeSalePage', '一件代发');
    } else {
      if (this.queryParams.proxySendStatus !== '1' && this.queryParams.proxySendStatus !== '3') {
        message.warning('请选择代发状态为未代发的订单');
        return;
      }

      console.log(`/egenie-ts-oms/wholeSalePage?vo=${JSON.stringify(this.queryParams)}&pageType=1`);
      window.top.egenie.openTab(`/egenie-ts-oms/wholeSalePage?vo=${JSON.stringify(this.queryParams)}&pageType=1`, 'wholeSalePage', '一件代发');
    }
  };
}
