import { message, Modal } from 'antd';
import type { BaseData, PaginationData } from 'egenie-common';
import { NormalProgramme, request } from 'egenie-utils';
import { action, observable } from 'mobx';
import moment from 'moment';
import { api } from '../../utils';
import { OrderGridStore } from '../components/orderGrid/store';
import { GRID_COLUMNS, ITEM_HEADER_COLUMNS } from './constant';
import type { IDict, IDictList, ISaleStatus, IShopList, IShopOrderList } from './interface';
import { ManualDownloadOrderModel } from './manualDownloadOrder/model';
import DropShippingTipStore from './dropShippingTip/store';

export class Store {
  constructor() {
    this.getShopList();
    this.getConfigStatus();
    this.getDictList();
    this.queryShopOrderList();
    this.getUnSendOrderList();
  }

  public manualDownloadOrderModel = new ManualDownloadOrderModel({ parent: this });

  public dropShippingTipStore = new DropShippingTipStore({ parent: this });

  @observable public loading = false;

  @observable public platformStateList = [];// 顶部状态

  @observable public activeStatus = -1;// 选中状态

  @observable public proxySendStatus: string = undefined;

  @observable public unSendOrderNum = 0;

  @action private getConfigStatus = (): void => {
    request<BaseData<ISaleStatus[]>>({ url: api.getStatusList }).then((res) => {
      let statusOptions = this.mapOptions(res.data, 'code', 'tag');
      statusOptions = statusOptions.map((item) => {
        item.number = item.count;
        return item;
      });
      this.platformStateList = statusOptions;
      this.programme.filterItems.addDict({ distributionState: statusOptions });
    });
  };

  private arrToArr = (array): any[] => {
    return array.map((item) => {
      const value = Object.keys(item)[0];
      return {
        label: item[value],
        value,
      };
    });
  };

  @action private getDictList = (): void => {
    request<BaseData<IDictList>>({
      url: api.getDictList,
      method: 'post',
      data: {
        dictList: 'proxy_send_status',
        module: 'shopOrder',
      },
    }).then((res) => {
      let proxySendStatus = res.data?.dictList?.proxy_send_status || [];
      proxySendStatus = this.arrToArr(proxySendStatus);
      this.programme.filterItems.addDict({ proxySendStatus: proxySendStatus.filter((v) => v.value !== '3') });
    });
  };

  @action public getUnSendOrderList = (): void => {
    request<PaginationData>({
      url: api.queryShopOrderList,
      method: 'post',
      data: {
        page: 1,
        pageSize: 10,
        createdTimeEnd: moment().format('YYYY-MM-DD HH:mm:ss'),
        createdTimeStart: moment(new Date()).subtract(24, 'hours')
          .format('YYYY-MM-DD HH:mm:ss'),
        proxySendStatus: 1,
      },
    }).then((res) => {
      this.unSendOrderNum = res.data.totalCount;
    });
  };

  @action public checkUnsendOrder = (): void => {
    this.programme.filterItems.reset();
    this.programme.filterItems.updateFilterItem([
      {
        type: 'select',
        field: 'proxySendStatus',
        value: '1',
      },
      {
        type: 'dateRange',
        field: 'payTime',
        label: '下单时间',
        startTime: moment(new Date()).subtract(24, 'hours'),
        endTime: moment(),
      },
    ]);
    this.programme.handleSearch();
  };

  // 查询店铺订单
  public queryShopOrderList = (page?: number): Promise<void> => {
    const params = this.programme.filterItems.params;
    if (params.payTime) {
      const time = (params.payTime as string).split(',');
      params.createdTimeStart = time[0] || undefined;
      params.createdTimeEnd = time[1] || undefined;
      delete params.payTime;
    }

    this.activeStatus = params.distributionState ? Number(params.distributionState) : -1;
    if (params.distributionState === '-100' || this.activeStatus === -100) {
      params.isInvalidated = '1';
      delete params.distributionState;
    }
    const pageInfo = this.orderGridStore.pageInfo;
    Object.assign(params, {
      page: page || pageInfo.page,
      pageSize: pageInfo.pageSize,
    });
    this.orderGridStore.loading = true;
    return request<PaginationData>({
      url: api.queryShopOrderList,
      method: 'post',
      data: params,
    }).then((res) => {
      this.orderGridStore.list = res.data.list;
      this.orderGridStore.pageInfo = {
        page: res.data.page,
        pageSize: res.data.pageSize,
        total: res.data.totalCount,
      };
    })
      .finally(() => {
        this.orderGridStore.loading = false;
      });
  };

  // 页码变化
  public onPageChange = (page: number, pageSize: number): void => {
    this.orderGridStore.pageInfo = {
      ...this.orderGridStore.pageInfo,
      page,
      pageSize,
    };
    this.queryShopOrderList();
  };

  // 查询方案
  public programme = new NormalProgramme({
    filterItems: [
      {
        type: 'input',
        field: 'platformOrderCode',
        label: '店铺单号',
      },
      {
        type: 'select',
        field: 'shopId',
        label: '店铺名称',
        mode: 'multiple',
        isParamList: false,
      },
      {
        type: 'select',
        field: 'distributionState',
        label: '订单状态',
      },
      {
        type: 'input',
        field: 'courierOrderNo',
        label: '快递单号',
      },
      {
        type: 'dateRange',
        field: 'payTime',
        label: '下单时间',
      },
      {
        type: 'select',
        field: 'proxySendStatus',
        label: '代发状态',
      },

    ],
    count: 6,
    handleSearch: () => {
      return this.queryShopOrderList(1);
    },
  });

  // 获取店铺列表
  @action private getShopList = (): void => {
    request<BaseData<IShopList>>({ url: api.getShopList }).then((res) => {
      this.programme.filterItems.addDict({ shopId: this.mapOptions(res.data, 'shopId', 'shopName') });
    });
  };

  // 处理顶部tab选项变化
  @action public handleTabChange = (key: number): void => {
    this.activeStatus = key;
    this.programme.filterItems.updateFilterItem([
      {
        field: 'distributionState',
        value: (key === -1) ? undefined : [`${key}`],
      },
    ]);
    this.queryShopOrderList(1);
  };

  // 处理选项
  private mapOptions = (data, key: string, val: string): IDict[] => {
    return data?.map((item) => ({
      label: item[val],
      value: item[key],
      number: item.count,
    }));
  };

  // 获取已选数据
  @action public getSelectedRows = (): IShopOrderList[] => {
    const arr = [];
    this.orderGridStore.list.forEach((item) => {
      this.orderGridStore.selectedIds.forEach((it) => {
        if (item.saleOrderId === it) {
          arr.push(item);
        }
      });
    });
    return arr;
  };

  /**
   * 一件代发
   */
  public handleWholesale = () => {
    const selectRows = this.orderGridStore.selectedRows;
    const queryParams = this.programme.filterItems.params;
    this.dropShippingTipStore.toggleDropModal(true, selectRows, queryParams);
  };

  // 批量代发
  @action public batchDropShip = (id?: number, tenantId?: number): void => {
    if (!id && !this.orderGridStore.selectedIds.length) {
      message.warning('请至少选择一条数据');
      return;
    }
    if (!id && this.orderGridStore.selectedIds.length) {
      const selectedRows = this.getSelectedRows();
      const isLegalData = selectedRows.every((v) => v.proxySendStatus === 1);
      if (!isLegalData) {
        message.warning('包含已代发或无需代发数据');
        return;
      }
    }
    const ids = id || Array.from(this.orderGridStore.selectedIds);
    const tenantIds = tenantId || this.orderGridStore.selectedRows.map((item) => item.tenantId);
    window.top.egenie.openTab(`/egenie-ts-oms/wholeSalePage?ids=${ids}&tenantIds=${tenantIds}`, 'wholeSalePage', '一件代发');
  };

  // 批量作废
  @action public batchInvalid = (id?: number): void => {
    if (!id && !this.orderGridStore.selectedIds.length) {
      message.warning('请至少选择一条数据');
      return;
    }
    if (!id && this.orderGridStore.selectedIds.length) {
      const selectedRows = this.getSelectedRows();
      const isLegalData = selectedRows.every((v) => v.isInvalidated !== 1);
      if (!isLegalData) {
        message.warning('包含已作废数据');
        return;
      }
    }
    const ids = id ? `${id}` : this.orderGridStore.selectedIds.toString();
    Modal.confirm({
      title: '提示',
      content: '是否确认作废订单？',
      onOk: () => {
        return request<BaseData>({
          url: api.invalidateOrders,
          method: 'post',
          data: { ids },
        }).then((res) => {
          message.success(res.info || '操作成功');
          this.queryShopOrderList();
        });
      },
    });
  };

  @action public matchGoods = (platformProductId) => {
    if (!platformProductId) {
      message.error('找不到平台商品');
      return;
    }
    top.egenie.openTab(`/egenie-ts-vogue/matchGoods/index?gmsGoodsIdList=${JSON.stringify([platformProductId])}&type=2`, 'matchGoods', '关联商品');
  };

  public orderGridStore = new OrderGridStore({
    list: [],
    primaryKey: 'saleOrderId',
    detailField: 'details',
    detailHeight: 102,
    detailKey: 'saleOrderDetailId',
    itemHeaderColumns: ITEM_HEADER_COLUMNS(this),
    gridColumns: GRID_COLUMNS(this),
    onPageChange: this.onPageChange,
    showRefresh: true,
  });
}
