import { Button } from 'antd';
import React from 'react';
import noData from '../../../assets/images/noData.png';
import styles from './index.less';

interface Props {
  onQuery?: () => any;
  showNoSearchFlag?: boolean;
}

// 自定义订单表格-空数据
export const GridEmpty = (props: Props) => {
  return (
    <div className={styles.emptyWrapper}>
      <img
        src={noData}
        style={{
          height: 80,
          marginBottom: 8,
        }}
      />
      <div style={{ color: '#000' }}>
        {
          props.showNoSearchFlag ? '暂无数据哦!' : '点击立即查询后，呈现数据！'
        }

      </div>
      {props?.onQuery && (
        <Button
          className={styles.btn}
          onClick={() => {
            props?.onQuery && props?.onQuery();
          }}
          type="primary"
        >
          点击查询
        </Button>
      )}
    </div>
  );
};

