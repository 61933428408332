import { Checkbox, Pagination, Spin } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { GridEmpty } from '../gridEmpty';
import styles from './index.less';
import type { EachColumnProps, EachHeaderProps, OrderGridStore } from './store';
import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

// 整个一单多货订单列表
export const OrderGrid: React.FC<{ store: OrderGridStore; }> = observer((props) => {
  const { list, primaryKey, loading, itemHeaderColumns, gridColumns, detailKey, detailField, detailHeight, handleAllSelectChecked, allChecked, handleEachItemChecked, mergeWrapperWidth, itemHeaderRightContent, showCheckbox, ref } = props.store;

  const Row = ({ index, style }) => {
    const row = list[index];
    const rowRef = React.useRef(null);

    useEffect(() => {
      if (rowRef.current) {
        listRef.current.resetAfterIndex(0);
      }
    }, [rowRef]);

    return (
      <div
        className={styles.eachItem}
        key={row[primaryKey]}
        ref={rowRef}
        style={style}
      >
        <ItemHeader
          checked={row.checked}
          handleEachItemChecked={handleEachItemChecked}
          itemHeaderColumns={itemHeaderColumns}
          rightContent={itemHeaderRightContent}
          row={row}
          rowIndex={index}
          showCheckbox={showCheckbox}
        />
        <ItemContent
          detailField={detailField}
          detailHeight={detailHeight}
          detailKey={detailKey}
          gridColumns={gridColumns}
          mergeWrapperWidth={mergeWrapperWidth}
          row={row}
        />
      </div>
    );
  };

  const getItemSize = (index) => detailHeight * list[index][detailField].length + 42;
  const listRef = React.useRef(null);

  const [, setState] = React.useState();
  const forceUpdate = () => setState([] as any);

  useEffect(() => {
    forceUpdate();
  }, [props.store.selectedIds.length]);

  return (
    <div className={styles.gridWrapper}>
      <ListHeader
        allChecked={allChecked}
        gridColumns={gridColumns}
        handleAllSelectChecked={handleAllSelectChecked}
        mergeWrapperWidth={mergeWrapperWidth}
        showCheckbox={showCheckbox}
      />
      <div
        className={styles.contentWrapper}
      >
        <Spin
          spinning={loading}
          wrapperClassName={styles.spin}
        >
          {
            Array.isArray(list) && list.length > 0 ? (
              <AutoSizer>
                {({ height, width }) => {
                  return (
                    <div ref={ref}>
                      <List
                        height={height}
                        itemCount={list.length}
                        itemSize={getItemSize}
                        layout="vertical"
                        ref={listRef}
                        width={width}
                      >
                        {Row}
                      </List>
                    </div>

                  );
                }}
              </AutoSizer>
            ) : <GridEmpty showNoSearchFlag/>
          }
        </Spin>
      </div>
      <Footer store={props.store}/>
    </div>
  );
});

// 底部分页和选中数目组件
const Footer: React.FC<{ store: OrderGridStore; }> = observer((props) => {
  const { pageInfo, selectedIds, handleAllSelectChecked, showCheckbox, showRefresh, refreshTable, handleShowSizeChange, handlePageChange } = props.store;
  return (
    <div className={styles.footer}>
      {
        showCheckbox ? (
          <div>
            已勾选
            <span className={styles.totalNum}>
              {selectedIds.length}
            </span>
            条
            <a
              className={styles.reset}
              onClick={() => {
                handleAllSelectChecked(false);
              }}
            >
              重置
            </a>
          </div>
        ) : <div/>
      }
      <div className={styles.footerRight}>
        <Pagination
          current={pageInfo.page}
          defaultPageSize={10}
          onChange={handlePageChange}
          onShowSizeChange={(page, pageSize) => {
            handleShowSizeChange(1, pageSize);
          }}
          pageSizeOptions={[
            '10',
            '50',
            '100',
            '150',
            '200',
          ]}
          showQuickJumper
          showSizeChanger
          showTotal={(total) => `共${total}条记录`}
          size="small"
          total={pageInfo.total}
        />
        {
          showRefresh ? (
            <div
              className={styles.refreshButton}
              onClick={refreshTable}
            >
              <i className={`icon-replace ${styles.refreshIcon}`}/>
              刷新
            </div>
          ) : null
        }
      </div>
    </div>
  );
});

interface IListHeaderProps {
  gridColumns: EachColumnProps[];// 表中的每一列列
  handleAllSelectChecked: (checked) => void;// 处理全选勾选
  allChecked: boolean;// 是否全选
  showCheckbox: boolean;// 是否展示勾选框
  mergeWrapperWidth: number;
}

// 表头header
const ListHeader: React.FC<IListHeaderProps> = React.memo((props) => {
  const { gridColumns, handleAllSelectChecked, allChecked, showCheckbox, mergeWrapperWidth } = props;

  // 分别计算合并的列和非合并列
  const noMergeColumns = []; const mergeColumns = [];
  gridColumns.forEach((item) => {
    item.merge ? mergeColumns.push(item) : noMergeColumns.push(item);
  });
  return (
    <div className={styles.header}>
      {/* 非合并列表头 */}
      <div
        className={styles.noMergeWrapper}
        style={{ display: 'flex' }}
      >
        {
          noMergeColumns.map((item, index) => (
            <div
              className={`${index === 0 ? (showCheckbox ? styles.flex : `${styles.noCheckboxFirst} ${styles.flex}`) : null} ${styles.headerGridCell}`}
              key={item.name}
              style={{ width: item.contentWidth }}
            >
              {
                (showCheckbox && index === 0) && (
                  <div>
                    <Checkbox
                      checked={allChecked}
                      className={styles.checkbox}
                      onChange={(e) => {
                        handleAllSelectChecked(e.target.checked);
                      }}
                    />
                    <span className={styles.allSelect}>
                      全选
                    </span>
                  </div>
                )
              }
              {item.name}
            </div>
          ))
        }
      </div>
      {/* 合并列表头 */}
      <div
        className={styles.mergeWrapper}
        style={{ width: `${mergeWrapperWidth}` }}
      >
        {
          mergeColumns.map((item, index) => (
            <div
              key={item.name}
              style={{ width: `${item.contentWidth}` }}
            >
              {item.name}
            </div>
          ))
        }
      </div>
    </div>
  );
});

interface IItemHeaderProps {
  itemHeaderColumns: EachHeaderProps[];
  checked: boolean;// 该项是否选中
  row: Record<string, any>;
  rowIndex: number;
  handleEachItemChecked: (checked: boolean, row, index: number) => void;
  showCheckbox: boolean;// 是否展示勾选框
  rightContent?: (row) => React.ReactNode;// 每一项表头最右边内容（备注）
}

// 每一项的header
const ItemHeader: React.FC<IItemHeaderProps> = React.memo((props) => {
  const { itemHeaderColumns, row, handleEachItemChecked, rowIndex, checked, rightContent, showCheckbox } = props;

  return (
    <div className={`${styles.header} ${styles.itemHeader}`}>
      {
        itemHeaderColumns.map((item, index) => (
          <div
            className={`${index === 0 ? (showCheckbox ? styles.flex : `${styles.noCheckboxFirst} ${styles.flex}`) : null} ${styles.headerGridCell}`}
            key={item.key}
            style={{ marginRight: 16 }}
          >
            {
              (showCheckbox && index === 0) && (
                <Checkbox
                  checked={checked}
                  className={styles.checkbox}
                  onChange={(e) => {
                    handleEachItemChecked(e.target.checked, row, rowIndex);
                  }}
                />
              )
            }
            {item.rowFormatter(row)}
          </div>
        ))
      }
      <div className={styles.itemHeaderRight}>
        {rightContent && rightContent(row)}
      </div>
    </div>
  );
});

interface IContentProps {
  row;
  gridColumns: EachColumnProps[];// 表格列
  detailKey: string;// 详情list key
  detailField: string;// 详情list 字段
  detailHeight: number;// 详情每一项的高度
  mergeWrapperWidth: number;// 合并列的总宽度
}

// 每一项内容
const ItemContent: React.FC<IContentProps> = React.memo((props) => {
  const { gridColumns, row, detailKey, detailField, detailHeight, mergeWrapperWidth } = props;

  // 分别计算合并的列和非合并列
  const noMergeColumns = []; const mergeColumns = [];
  gridColumns.forEach((item) => {
    item.merge ? mergeColumns.push(item) : noMergeColumns.push(item);
  });

  return (
    <div className={styles.eachContent}>
      <div
        className={styles.noMergeWrapper}
      >
        {
          row[detailField].map((detailItem) => (
            <div
              className={styles.eachDetail}
              key={detailItem[detailKey]}
              style={{ height: detailHeight }}
            >
              {noMergeColumns.map((item) => {
                return (
                  <div
                    className={styles.gridCell}
                    key={item.name}
                    style={{ width: `${item.contentWidth}` }}
                  >
                    {item.detailFormatter(detailItem, row)}
                  </div>
                );
              })}
            </div>
          ))
        }
      </div>
      <div
        className={styles.mergeWrapper}
        style={{
          height: detailHeight * row[detailField].length,
          width: `${mergeWrapperWidth}`,
        }}
      >
        {mergeColumns.map((item) => {
          return (
            <div
              className={styles.gridCell}
              key={item.name}
              style={{ width: `${item.contentWidth}` }}
            >
              {item.detailFormatter(row)}
            </div>
          );
        })}
      </div>
    </div>
  );
});

