import React from 'react';
import styles from './index.less';

interface TabProps{
  list: EachItem[];// 所有项
  activeKey: number | string;// 选中项
  onTabChange: (key: number | string) => void;// 改变选中项
  hideAll?: boolean;// 隐藏全部
}

interface EachItem{
  label: string;
  value: number | string;
  number?: number;
}

// 后台-订单-顶部选中tab组件
export const TopTab: React.FC<TabProps> = (props) => {
  const { list, activeKey, onTabChange, hideAll } = props;
  return (
    <div className={styles.tabWrapper}>
      {
        !hideAll && (
          <div
            className={`${styles.eachMenu} ${list.every((item) => item.value !== activeKey) && styles.activeTab} `}
            onClick={() => {
              onTabChange(-1);
            }}
          >
            全部
          </div>
        )
      }
      {
        list.map((item) => (
          <div
            className={`${styles.eachMenu} ${activeKey === item.value && styles.activeTab} `}
            key={item.value}
            onClick={() => {
              onTabChange(item.value);
            }}
          >
            {item.label}
            {item.number ? `(${item.number})` : null}
          </div>
        ))
      }
    </div>
  );
};
