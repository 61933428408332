import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Card, Space } from 'antd';
import { NormalProgrammeComponent } from 'egenie-utils';
import { observer } from 'mobx-react';
import React from 'react';
import { OrderGrid, TopTab } from '../components';
import styles from './index.less';
import { Store } from './store';
import { ManualDownloadOrder } from './manualDownloadOrder';
import { DropShippingTipModal } from './dropShippingTip';

const store = new Store();

const ShopOrder = observer(() => {
  const { programme, platformStateList, batchDropShip, activeStatus, handleTabChange, orderGridStore, handleWholesale, batchInvalid, unSendOrderNum, checkUnsendOrder, dropShippingTipStore } = store;
  return (
    <div className={styles.page}>
      <TopTab
        activeKey={activeStatus}
        list={platformStateList}
        onTabChange={handleTabChange}
      />
      <Card className={styles.card}>
        <NormalProgrammeComponent store={programme}/>
      </Card>
      <div className={styles.buttonWrapper}>
        <Space>
          <Button
            onClick={() => {
              batchDropShip();
            }}
            size="small"
          >
            批量代发
          </Button>
          <Button
            onClick={() => {
              batchInvalid();
            }}
            size="small"
          >
            批量作废
          </Button>
          {unSendOrderNum > 0 && (
            <div className={styles.unSendOrderList}>
              <ExclamationCircleFilled style={{ color: '#fb071f' }}/>
              <span className={styles.num}>
                有
                {unSendOrderNum}
                单超过24小时未代发
              </span>
              <span
                className={styles.checkOrder}
                onClick={checkUnsendOrder}
              >
                立即查看
              </span>
            </div>
          )}
          <Button
            onClick={() => {
              store.manualDownloadOrderModel.changeVisible(true);
            }}
            size="small"
          >
            手动下载订单
          </Button>
        </Space>
      </div>
      <div className={styles.orderWrapper}>
        <OrderGrid store={orderGridStore}/>
      </div>
      {/* 手动下载订单 */}
      <ManualDownloadOrder store={store.manualDownloadOrderModel}/>
      {/* 一件代发选择弹窗 */}
      <DropShippingTipModal store={dropShippingTipStore}/>
    </div>
  );
});

export default ShopOrder;
